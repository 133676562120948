(function(){
    /**
     * 
     * IMPORTS
     * 
     */

    /**
     * Import Addi-widget bundle
     */
     function importAddiWidgetBundle(page) {

        let bundle = (page === 'home' ) ? 'https://cdn.addi.com/home-banner-widget/prod/v1/home-banner-widget.esm.js'
                : 'https://cdn.addi.com/product-details-widget/prod/v1/product-details-widget.esm.js' ;

         const scriptElement = document.createElement('script');
         scriptElement.type = 'module';
         scriptElement.src = bundle;
         document.head.append(scriptElement);
    }

    /**
     * 
     * DATA INITIALIZATION
     * 
     */

    const ADDI_SCRIPT_TAG = document.querySelector('script[data-name="wooAddiHomeBanner"]');
    const ALLY_SLUG = ADDI_SCRIPT_TAG ? ADDI_SCRIPT_TAG.getAttribute('data-ally-slug') : null;
    const WIDGET_BANNER_DATA = {
        showBanner: false,
        bannerId: '',
        bannerElementRef: ''
    };
    const onScriptInitTime = new Date();

    let htmlElementReference = null;

    /**
     * 
     * METHODS
     * 
     */

    function addiBinds() {
        jQuery('addi-widget').on('onWidgetRenderedEvent', function () {
            // const onWdgRenderedTime = new Date();
            // if (typeof widgetTrackerAmplitude !== "undefined") {
            //     widgetTrackerAmplitude(window.amplitude_config.AMPLITUDE_EVENT['WIDGET_RENDERED'], {
            //         onScriptInitTimeMs: onScriptInitTime.getTime(),
            //         onWdgRenderedTimeMs: onWdgRenderedTime.getTime(),
            //         onWdgRenderedDurationMs: onWdgRenderedTime.getTime() - onScriptInitTime.getTime()
            //     });
            // }
        });

        jQuery('addi-widget').on('onWidgetClickedEvent', function () {
            const onWdgRenderedTime = new Date();
            // if (typeof widgetTrackerAmplitude !== "undefined") {
            //     widgetTrackerAmplitude(window.amplitude_config.AMPLITUDE_EVENT['WIDGET_CLICKED'], {
            //         onScriptInitTimeMs: onScriptInitTime.getTime(),
            //         onWdgRenderedTimeMs: onWdgRenderedTime.getTime(),
            //         onWdgRenderedDurationMs: onWdgRenderedTime.getTime() - onScriptInitTime.getTime()
            //     });
            // }
        });
    }

    /**
     * This function will insert a newNode into the DOM just after the given referenceNode
     * @param {*} newNode 
     * @param {*} referenceNode 
     */

    function insertAfter(newNode, referenceNode) {
        referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    }

    function onProductPageLoaded() {
        addiBinds();
        const PAGE_CATEGORY = getPageCategoryFromUrl();

        if (typeof addiParams !== 'undefined') {
            if (PAGE_CATEGORY === 'Home' && ADDI_SCRIPT_TAG != null) {
                // Amplitude script start event
                // if (typeof widgetTrackerAmplitude !== "undefined") {
                //     widgetTrackerAmplitude(window.amplitude_config.AMPLITUDE_EVENT['SCRIPT_STARTED'], {
                //         onScriptInitTimeMs: onScriptInitTime.getTime(),
                //     });
                // }
                WIDGET_BANNER_DATA.showBanner = ADDI_SCRIPT_TAG.getAttribute('data-show-banner') === 'true';
                WIDGET_BANNER_DATA.bannerElementRef = ADDI_SCRIPT_TAG.getAttribute('data-banner-element-reference');
                WIDGET_BANNER_DATA.bannerId = ADDI_SCRIPT_TAG.getAttribute('data-banner-id');
                if (WIDGET_BANNER_DATA.showBanner) {
                    renderAddiHomeBanner();
                }
            } else if(addiParams.is_product == "true") {
                // Amplitude script start event
                // if (typeof widgetTrackerAmplitude !== "undefined") {
                //     widgetTrackerAmplitude(window.amplitude_config.AMPLITUDE_EVENT['SCRIPT_STARTED'], {
                //         onScriptInitTimeMs: onScriptInitTime.getTime(),
                //     });
                // }
                importAddiWidgetBundle('product');
            }
        } else {
            if (PAGE_CATEGORY === 'Home' && ADDI_SCRIPT_TAG != null) {
                WIDGET_BANNER_DATA.showBanner = ADDI_SCRIPT_TAG.getAttribute('data-show-banner') === 'true';
                WIDGET_BANNER_DATA.bannerElementRef = ADDI_SCRIPT_TAG.getAttribute('data-banner-element-reference');
                WIDGET_BANNER_DATA.bannerId = ADDI_SCRIPT_TAG.getAttribute('data-banner-id');
                if (WIDGET_BANNER_DATA.showBanner) {
                    renderAddiHomeBanner();
                }
            } else {
                importAddiWidgetBundle();
            }
        }

    }

    /**
     * Get location
     */

    function getPageCategoryFromUrl() {
        const defaultHomePageRoutes = [
            '/'
        ];

        if (typeof addiParams !== 'undefined') {
            defaultHomePageRoutes.push(addiParams.home_url);
        }
        const URLpathName = window.location.pathname
        return defaultHomePageRoutes.some(HomeRoute => URLpathName === HomeRoute) ? 'Home' : '';
    }

    function renderAddiHomeBanner() {
        importAddiWidgetBundle('home');

        const elementReference = htmlElementReference || getReferenceNode(WIDGET_BANNER_DATA.bannerElementRef);

        if (elementReference) {

            const banner = document.querySelector('addi-home-banner');
            banner ? banner.remove() : null;
            const addiHomeBanner = document.createElement('addi-home-banner');
    
            if (WIDGET_BANNER_DATA.bannerId) {
                addiHomeBanner.setAttribute('ally-slug', ALLY_SLUG);
                addiHomeBanner.setAttribute('banner-id', WIDGET_BANNER_DATA.bannerId);
            }
            
            insertAfter(addiHomeBanner, elementReference);

        } else {

            console.log('No reference node was not found for home banner.')

        }
    }

    /**
     * 
     * METHODS
     * 
     */


     function getReferenceNode(reference) {

        if (reference) {
            htmlElementReference = document.querySelector(reference);

            return htmlElementReference;
        }
    }
        
    /**
     * Event's listeners
     */

    if (document.readyState === "complete" || document.readyState === "interactive") {
        onProductPageLoaded();
    }
    else {
        document.addEventListener('DOMContentLoaded', onProductPageLoaded);
    }

})();
